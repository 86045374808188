.App {
  text-align: center;
}

.glow-on-hover {
  width: 320px;
  height: 70px;
  opacity: 90%;
  border: #1d756c;
  outline: #1d756c;
  color: #ffffff;
  cursor: pointer;
  font-family: Georgia, 'Times New Roman', Times, serif;
  position: relative;
  background: #f6d3b6;
  font-size: 30px;
  font-weight: 300;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #99e1ac, #f6d3b6, #f6d3b6, #daf2e5);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  border-radius: 5px;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #1d756c
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 100%;
  color: #f8f8f4;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}